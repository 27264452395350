import React, { useEffect, useState } from "react"; //, createContext
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import Moment from 'moment';
import { config } from '../Constants'


function Experience()
{
	const [exps, setExperience] = useState([]);

	const baseURL = config.url.API_URL; // '/api/'; //'http://localhost/repulsivecoder.pro/api/'; // 

	const init = async () => {
		const url = baseURL + 'experience';

		fetch(url, {method: 'GET', headers: { 'Content-Type' : 'application/json', mode:'cors' }  }).then((res) => { if (res.ok) { return res.json(); } throw new Error(res.status); }).then((data) => { if (data.success) setExperience(data.data); } );
	};

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		init(); }, []);

	return (
		<div className="experience">
			<VerticalTimeline lineColor='#3e497a'>
				{exps.map((exp, idx) => {
					var CustomIcon = <WorkIcon />;
					var sdate = Moment(exp.sdate).format('MMMM D, Y');
					var edate = Moment(exp.edate).format('MMMM D, Y');

					if (exp.type === 'School')
					{
						CustomIcon = <SchoolIcon />;
					}

					if (exp.displayonlyyear === '1')
					{
						sdate = Moment(exp.sdate).format('Y');
						edate = Moment(exp.edate).format('Y');
					}
					else if (exp.hidedate === '1')
					{
						sdate = Moment(exp.sdate).format('MMMM Y');
						edate = Moment(exp.edate).format('MMMM Y');
					}

					var date = sdate + ' - ';

					if (exp.currentlyworking === '1')
					{
						date += 'Current';
					}
					else
					{
						date += edate;
					}

					return (
					<VerticalTimelineElement key={idx} className="vertical-timeline-element--education" date={date} iconStyle={{ background: "#3e497a", color: "#fff" }} icon={CustomIcon} >
						<h3 className="vertical-timeline-element-title">{exp.title}</h3>
						<h4 className="nounderline" dangerouslySetInnerHTML={{ __html: exp.institution }} />
						{/*<p style={{whiteSpace: "pre-wrap"}}>{exp.description}</p>*/}
						<span dangerouslySetInnerHTML={{ __html: exp.description }} />
					</VerticalTimelineElement>
					);
				})}
			</VerticalTimeline>
		</div>
	);
}


export default Experience;
