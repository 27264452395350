import React from "react";
import { useNavigate } from "react-router-dom";
//import Moment from 'moment';

function ProjectItem({ images, name, id, date, uri }) {
  const navigate = useNavigate();

  return (
    <div
      className="projectItem"
      onClick={() => {
        navigate("/project/" + (uri !== undefined && uri !== null ? uri : id));
      }}
    >
      <div style={{ backgroundImage: `url(/media/project/${images[0]})` }} className="bgImage" />
      <h1> {name} </h1>
      <p> {date} </p>
    </div>
  );
}

export default ProjectItem;
