const prod = {

	url: {
		API_URL: '/api/',
	}
};

const dev = {
	url: {
		API_URL: 'http://localhost/repulsivecoder.pro/api/'
	}
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;