import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YoutubeIcon from "@mui/icons-material/YouTube";
import GithubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import FlickrIcon from "@mui/icons-material/Camera";
//import TikTokIcon from "@mui/icons-material/LibraryMusic";


import "../styles/Home.css";


const TikTokIcon = ({ color = "#FFFFFF" }) => {
  return (
    <div style={{height: '60px', width: '80px', display: 'inline-block', maxHeight: '60px' }}><svg
	  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
      fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" style={{width: '100%', height: '100%'}} >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg></div>
  );
};
const TwitchIcon = ({ color = "#FFFFFF" }) => {
  return (
    <div style={{height: '60px', width: '80px', display: 'inline-block', maxHeight: '60px' }}><svg fill={color} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="80" height="60" viewBox="0 0 24 24">
    <path d="M 5.3632812 2 L 2 6.6367188 L 2 20 L 7 20 L 7 23 L 10 23 L 13 20 L 17 20 L 22 15 L 22 2 L 5.3632812 2 z M 6 4 L 20 4 L 20 13 L 17 16 L 12 16 L 9 19 L 9 16 L 6 16 L 6 4 z M 11 7 L 11 12 L 13 12 L 13 7 L 11 7 z M 16 7 L 16 12 L 18 12 L 18 7 L 16 7 z"></path>
    </svg></div>
  );
};



function Home() {
  return (
    <div className="home">
      <div className="about">
        <h2>Abdullah Ibne Alam</h2>
        <h3>Crafting Innovative Solutions through Code</h3>
        <div className="prompt">
          <p>Innovative software developer proficient in crafting full-stack applications, mobile app development, Internet of Things (IoT) solutions, and Robotic Process Automation (RPA).</p>
          <a href="https://www.linkedin.com/in/abdullah-ibne-alam/" target="_blank" rel="noreferrer"><LinkedInIcon /></a>
          <a href="https://www.twitch.tv/RepulsiveCoder" target="_blank" rel="noreferrer"><TwitchIcon /></a>
          <a href="https://github.com/RepulsiveCoder/" target="_blank" rel="noreferrer"><GithubIcon /></a>
          <a href="https://www.facebook.com/RepulsiveCoder" target="_blank" rel="noreferrer"><FacebookIcon /></a>
          <a href="https://www.tiktok.com/@repulsivecoder" target="_blank" rel="noreferrer"><TikTokIcon /></a>
          <a href="https://twitter.com/RepulsiveCoder" target="_blank" rel="noreferrer"><TwitterIcon /></a>
          <a href="https://youtube.com/@RepulsiveCoderHome" target="_blank" rel="noreferrer"><YoutubeIcon /></a>
          <a href="https://www.instagram.com/RepulsiveCoder/" target="_blank" rel="noreferrer"><InstagramIcon /></a>
          <a href="https://www.flickr.com/photos/repulsivecoder/" target="_blank" rel="noreferrer"><FlickrIcon /></a>
        </div>
      </div>
      <div className="skills">
        <h1> Skills</h1>
        <ol className="list">
          <li className="item">
            <h2>Languages</h2>
            <span>Experienced in PHP, Flutter & Dart, JavaScript, Java, C/C++, Python, and PEARL.</span>
          </li>
          <li className="item">
            <h2>Back-End</h2>
            <span>
              Proficient in PHP, Laravel, NodeJS, Java Spring, Django, and experienced in working with databases such as MySQL, Oracle, PostgreSQL, and MongoDB.
            </span>
          </li>
          <li className="item">
            <h2> Front-End</h2>
            <span>
              Skilled in ReactJS, Vue.js, and adept at using frontend frameworks like Bootstrap, MaterialUI, and TailwindCSS.
            </span>
          </li>
          <li className="item">
            <h2>Others</h2>
            <span>Familiar with SMPP, Image Processing, Video Editing, CAD, CNC, and proficient in Media Streaming</span>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Home;
